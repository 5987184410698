import Services from '../services'

class JobMixDesignApi {
    // API SERVER
    get(params) {
        return Services.get('approval_jobmix_design/list', { params })
    }

    getHistory(params) {
        return Services.get('approval_jobmix_design/history/list', { params })
    }

    getSingle(params) {
        return Services.get('approval_jobmix_design/detail', { params })
    }

    approve(data) {
        return Services.post('approval_jobmix_design/approve', data)
    }
}

export default new JobMixDesignApi()