import { dateConvert } from 'utilities2';

export const formSubmitValueMapper = (data = {}) => ({
  id_pengajuan_petty_cash: data?.id_pengajuan_petty_cash ?? undefined,
  tgl_pengajuan_petty_cash: dateConvert().getDashYMD(
    new Date(data.tgl_pengajuan_petty_cash)
  ),
  id_penerima_petty_cash: data?.id_penerima_petty_cash,
  kode_modul: data?.kode_modul,
  keperluan: data?.keperluan,
  nominal_pengajuan: data?.nominal_pengajuan,
});
