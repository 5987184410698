import Services from 'services'

class SalesOrderSPKApi {
    get(params) {
        return Services.get('approval_sales_order_spk/list', { params })
    }

    getHistory(params) {
        return Services.get('approval_sales_order_spk/history', { params })
    }

    getSingleSOSPK(params) {
        return Services.get('approval_sales_order_spk/detail', { params })
    }

    approve(data) {
        return Services.post('approval_sales_order_spk/approve', data)
    }
}

export default new SalesOrderSPKApi()