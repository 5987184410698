import Services from 'services';

class RealisasiPettyCashApi {
  get(params) {
    return Services.get('approval_realisasi_petty_cash/list', { params });
  }

  getHistory(params) {
    return Services.get('approval_realisasi_petty_cash/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('approval_realisasi_petty_cash/detail', {
      params,
    });
  }

  approve(data) {
    return Services.post('approval_realisasi_petty_cash/approve', data);
  }
}

export default new RealisasiPettyCashApi();
