import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { ApprovalSeleksiVendorApi } from "api";

const DetailApprovalSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const noSeleksiVendor = state?.no_seleksi_vendor;
  const tab = state?.tab ?? "";
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalSeleksiVendorApi.getSingle({ no_seleksi_vendor: noSeleksiVendor })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataSeleksiVendor(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoSeleksiVendor = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: dataSeleksiVendor.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataSeleksiVendor.detail.baseline,
    };

    ApprovalSeleksiVendorApi.save(finalValues)
      .then(() =>
        history.push("/human-resource/approval/seleksi-vendor", {
          alert: {
            show: true,
            variant: "primary",
            text: "Approval berhasil disimpan!",
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  useEffect(() => {
    setNavbarTitle("Seleksi Vendor");
    noSeleksiVendor ? getInitialData() : whenNoSeleksiVendor();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        fontSize: "14px",
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        fontSize: "14px",
      }}
    >
      {props.children}
    </td>
  );

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Purchase Request"
                value={
                  dataSeleksiVendor?.detail?.tgl_purchase_request
                    ? DateConvert(
                        new Date(
                          dataSeleksiVendor?.detail?.tgl_purchase_request
                        )
                      ).detail
                    : "-"
                }
              />
              <InfoItem
                title="Tgl. Pemakaian"
                value={
                  dataSeleksiVendor?.detail?.tgl_pemakaian
                    ? dataSeleksiVendor?.detail?.tgl_pemakaian
                    : "-"
                }
              />
              <InfoItem
                title="Tgl. Seleksi Vendor"
                value={
                  dataSeleksiVendor?.detail?.tgl_seleksi_vendor
                    ? DateConvert(
                        new Date(dataSeleksiVendor?.detail?.tgl_seleksi_vendor)
                      ).detail
                    : "-"
                }
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="No. Purchase Request"
                value={
                  dataSeleksiVendor?.detail?.no_purchase_request
                    ? dataSeleksiVendor?.detail?.no_purchase_request
                    : "-"
                }
              />
              <InfoItem
                title="Keperluan"
                value={
                  dataSeleksiVendor?.detail?.keperluan
                    ? dataSeleksiVendor?.detail?.keperluan
                    : "-"
                }
              />
              <InfoItem
                title="No. Seleksi Vendor"
                value={
                  dataSeleksiVendor?.detail?.no_seleksi_vendor
                    ? dataSeleksiVendor?.detail?.no_seleksi_vendor
                    : "-"
                }
              />
              <InfoItem
                title="Proyek"
                value={
                  dataSeleksiVendor?.detail?.nama_proyek
                    ? dataSeleksiVendor?.detail?.nama_proyek
                    : "-"
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const TablePurchaseRequestSection = () => {
    const dataTable = dataSeleksiVendor?.detail?.pr_detail
      ? dataSeleksiVendor.detail.pr_detail
      : [];

    const getTotal = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty_pr ? current.qty_pr : 0;
        const price = current.harga_perkiraan_sendiri
          ? current.harga_perkiraan_sendiri
          : 0;
        const total = parseInt(price) * parseFloat(qty);

        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal;
    };

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;

      const total = parseFloat(dataQty) * parseInt(dataHarga);
      return RupiahConvert(total.toString()).getWithComa;
    };

    return (
      <>
        <div className="p-1 py-2">
          <b>List Item Purchase Request</b>
        </div>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              {/* <Th>No.</Th> */}
              <Th>Kode Item</Th>
              <Th>Nama Item</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty PR</Th>
              <Th>Harga Perkiraan Sendiri</Th>
              <Th>Sub Total Harga</Th>
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  {/* <Td className="text-center">{index + 1}</Td> */}
                  <Td>{val.kode_item}</Td>
                  {/* <Td>
                    {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                            ? val.nama_item
                            : ""}
                  </Td> */}
                  <Td>{val.nama_item}</Td>
                  <Td>{val.satuan_beli}</Td>
                  <Td className="text-right">
                    {decimalConvert(val.qty_pr ?? 0)}
                  </Td>
                  <Td className="text-right">
                    {val.harga_perkiraan_sendiri
                      ? RupiahConvert(
                          parseInt(val.harga_perkiraan_sendiri).toString()
                        ).getWithComa
                      : "-"}
                  </Td>
                  <Td className="text-right">
                    {getSubTotal(
                      val.qty_pr,
                      parseInt(val.harga_perkiraan_sendiri).toString()
                    )}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {/* <tr>
              <Th colSpan={6} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">{RupiahConvert(getTotal().toString()).detail}</Th>
            </tr> */}
          </tfoot>
        </Table>
      </>
    );
  };

  const TableSeleksiVendorSection = () => {
    const dataTable = dataSeleksiVendor?.detail?.detail
      ? dataSeleksiVendor.detail.detail
      : [];

    const getTotal = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty_order ? current.qty_order : 0;
        const price = current.harga_kesepakatan ? current.harga_kesepakatan : 0;
        const total = parseInt(price) * parseFloat(qty);

        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal;
    };

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;
      const total = parseFloat(dataQty) * parseInt(dataHarga);

      return RupiahConvert(total.toString()).getWithComa;
    };

    return (
      <>
        <div className="p-1 py-2">
          <b>List Item Seleksi Vendor</b>
        </div>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              {/* <Th>No.</Th> */}
              <Th>Nama Vendor</Th>
              <Th>Kode Item</Th>
              <Th>Nama Item</Th>
              <Th>Satuan</Th>
              <Th>Qty Order</Th>
              <Th>Harga Kesepakatan</Th>
              <Th>Sub Total Harga</Th>
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  {/* <Td className="text-center">{index + 1}</Td> */}
                  <Td>{val.nama_vendor}</Td>
                  <Td>{val.kode_item}</Td>
                  {/* <Td>
                    {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                            ? val.nama_item
                            : ""}
                  </Td> */}
                  <Td>{val.nama_item}</Td>
                  <Td>{val.satuan_beli}</Td>
                  <Td className="text-right">
                    {decimalConvert(val.qty_order ?? 0)}
                  </Td>
                  <Td className="text-right">
                    {val.harga_kesepakatan
                      ? RupiahConvert(
                          parseInt(val.harga_kesepakatan).toString()
                        ).getWithComa
                      : "-"}
                  </Td>
                  <Td className="text-right">
                    {getSubTotal(val.qty_order, val.harga_kesepakatan)}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {/* <tr>
              <Th colSpan={7} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">{RupiahConvert(getTotal().toString()).detail}</Th>
            </tr> */}
          </tfoot>
        </Table>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty,
  }) => {
    const dataPengaju = dataSeleksiVendor?.approval
      ? dataSeleksiVendor.approval
      : [];
    const dataDetail = dataSeleksiVendor.detail
      ? dataSeleksiVendor.detail
      : "-";

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Seleksi Vendor</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER"
                      ? `Pemeriksa ${val.approval_level}`
                      : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Seleksi Vendor
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Seleksi Vendor
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Seleksi Vendor
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "revise",
                            title: (
                              <span className="text-warning">
                                Revise Seleksi Vendor
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Seleksi Vendor
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };
  const formValidation = Yup.object().shape({
    catatan: Yup.string().required("Masukan Catatan"),
  });

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Seleksi Vendor</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TablePurchaseRequestSection />
              <TableSeleksiVendorSection />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={formValidation}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            validateForm,
            errors,
            touched,
            setTouched,
            dirty,
          }) => (
            <>
              <FormCard
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                validateForm={validateForm}
                setTouched={setTouched}
                dirty={dirty}
              />
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalSeleksiVendor;
