import React, { useState, useEffect } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Formik } from "formik";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
  ButtonCancel,
} from "components2";
import { tableNumber, dateConvert, rupiahConvert } from "utilities2";
import { useModalConfirm } from "hooks2";
import {
  FormPengajuanPettyCashContent,
  DetailPengajuanPettyCashContent,
  CatatanApprovalPengajuanPettyCashContent,
} from "./__PengajuanPettyCashComps__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  STATUS_APPROVAL,
  MODAL_TITLE,
} from "./__PengajuanPettyCashUtils__";
import PengajuanPettyCashApi from "./__PengajuanPettyCashApi__";

export const PengajuanPettyCashList = ({ setNavbarTitle }) => {
  const modalConfirm = useModalConfirm();

  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });
  const [modal, setModal] = useState({
    type: "CREATE", // CREATE | UPDATE | DETAIL
    show: false,
    data: {},
  });

  const getPengajuanPettyCash = useQuery(
    ["pengajuan_petty_cash", "list", pagination],
    () => PengajuanPettyCashApi.getList(pagination)
  );

  const createPengajuanPettyCash = useMutation((data) =>
    PengajuanPettyCashApi.create(data)
  );

  const updatePengajuanPettyCash = useMutation((data) =>
    PengajuanPettyCashApi.update(data)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = () =>
    setModal({ show: true, type: "CREATE" });

  const onDetailButtonClickHandler = (data) =>
    setModal({ show: true, type: "DETAIL", data });

  const onUpdateButtonClickHandler = (data) =>
    setModal({ show: true, type: "UPDATE", data });

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: "md",
      type: modal.type === "CREATE" ? "create" : "update",

      component: (
        <Formik initialValues={values}>
          <FormPengajuanPettyCashContent action="DETAIL" />
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        if (modal.type === "CREATE") {
          return await createPengajuanPettyCash
            .mutateAsync(formSubmitValueMapper(values), resetForm)
            .then(() => {
              modalConfirm.infoSuccess({
                title: "Data Pengajuan Petty Cash Berhasil Ditambah",
                customTextInfoDetail: "Data telah tersimpan ke database",
                size: "md",
                onHide: () => {
                  modalConfirm.close();
                  dismissModal(resetForm);
                },
              });
              getPengajuanPettyCash.refetch();
            })
            .catch((err) => {
              modalConfirm.infoError({
                title: "Data Pengajuan Petty Cash Gagal Ditambah",
                customTextInfoTitle: "Penyebab Kegagalan:",
                customTextInfoDetail: err?.response?.data?.message,
              });
            });
        }

        await updatePengajuanPettyCash
          .mutateAsync(formSubmitValueMapper(values))
          .then(() => {
            modalConfirm.infoSuccess({
              title: "Data Pengajuan Petty Cash Berhasil Diubah",
              customTextInfoDetail: "Data telah tersimpan ke database",
              size: "md",
              onHide: () => {
                modalConfirm.close();
                dismissModal(resetForm);
              },
            });
            getPengajuanPettyCash.refetch();
          })
          .catch((err) =>
            modalConfirm.infoError({
              title: "Data Pengajuan Petty Cash Gagal Diubah",
              customTextInfoTitle: "Penyebab Kegagalan:",
              customTextInfoDetail: err?.response?.data?.message,
            })
          );
      },
      onHide: () => modalConfirm.close(),
    });
  };

  const dismissModal = (resetForm) => {
    resetForm();
    setModal({ data: null, show: false, type: "" });
  };

  useEffect(() => setNavbarTitle("Pengajuan Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPengajuanPettyCash.isLoading || getPengajuanPettyCash.isError ? (
        <DataStatus
          loading={getPengajuanPettyCash.isLoading}
          text={
            getPengajuanPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Pengajuan Petty Cash
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Pengajuan Petty <br /> Cash
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Proyek
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Nominal Pengajuan Petty <br /> Cash
                </Th>
                <Th style={{ width: 300 }} className="p-1">
                  Keperluan
                </Th>
                <Th style={{ width: 10 }} className="p-1">
                  Status Approval
                </Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getPengajuanPettyCash?.data?.data?.length > 0 ? (
                getPengajuanPettyCash?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_pengajuan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_pengajuan_petty_cash}</div>
                    </Td>
                    <Td>{e.nama_proyek}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(String(e.nominal_pengajuan))}
                    </Td>
                    <Td>{e.keperluan}</Td>
                    <Td>{STATUS_APPROVAL[e.status_approval ?? "PEN"]}</Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() => onDetailButtonClickHandler(e)}
                        />
                        {e.status_approval === "REV" && (
                          <ButtonUpdate
                            icon
                            noText
                            onClick={() => onUpdateButtonClickHandler(e)}
                          />
                        )}
                      </ButtonGroup>
                    </TdFixed>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={getPengajuanPettyCash?.data?.data_count}
            currentPage={pagination?.page}
            totalPage={getPengajuanPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {/* Modal Create | Update | Detail */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal?.data, modal?.type)}
        validationSchema={formValidationSchema}
        onSubmit={onSubmitForm}
      >
        {(formik) => (
          <Modal
            show={modal.show}
            size={modal.type === "CREATE" ? "md" : "lg"}
            onHide={() => dismissModal(formik.resetForm)}
          >
            <Modal.Header closeButton>
              <span>{MODAL_TITLE[modal.type]} Pengajuan Petty Cash </span>
            </Modal.Header>
            <Modal.Body>
              {modal.type === "DETAIL" ? (
                <DetailPengajuanPettyCashContent />
              ) : (
                <FormPengajuanPettyCashContent action={modal.type} />
              )}

              {modal.type !== "CREATE" && (
                <CatatanApprovalPengajuanPettyCashContent />
              )}
            </Modal.Body>

            <Modal.Footer>
              <div className="d-flex justify-content-center">
                <ButtonCancel
                  className="mr-2"
                  disabled={formik.isSubmitting}
                  onClick={() => dismissModal(formik.resetForm)}
                />

                {modal.type === "CREATE" && (
                  <ButtonCreate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}

                {modal.type === "UPDATE" && (
                  <ButtonUpdate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </CRUDLayout>
  );
};
