import React, { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
} from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
} from 'components2';
import { tableNumber, dateConvert, rupiahConvert } from 'utilities2';
import { STATUS_APPROVAL } from './__PenerimaPettyCashUtils__';
import PenerimaanPettyCashApi from './__PenerimaanPettyCashApi__';

export const PenerimaanPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: '',
    page: 1,
    per_page: 10,
  });

  const getPenerimaPettyCash = useQuery(
    ['penerimaan_petty_cash', 'list', pagination],
    () => PenerimaanPettyCashApi.getList(pagination)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };
  const onCreateButtonClickHandler = () =>
    history.push(
      '/human-resource/transaksi/penerimaan-petty-cash/mutasi-petty-cash'
    );

  const onDetailButtonClickHandler = (id) =>
    history.push(
      `/human-resource/transaksi/penerimaan-petty-cash/detail/${id}`
    );

  const onUpdateButtonClickHandler = (id) =>
    history.push(`/human-resource/transaksi/penerimaan-petty-cash/ubah/${id}`);

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  useEffect(() => setNavbarTitle('Penerimaan Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPenerimaPettyCash.isLoading || getPenerimaPettyCash.isError ? (
        <DataStatus
          loading={getPenerimaPettyCash.isLoading}
          text={
            getPenerimaPettyCash.isLoading
              ? 'Memuat . . .'
              : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Penerimaan Petty Cash
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed rowSpan={2}>No.</ThFixed>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Informasi Penerimaan <br /> Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Informasi Bukti Mutasi
                </Th>
                <Th rowSpan={2} style={{ width: 120 }} className="p-1">
                  Proyek
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Nominal Penerimaan <br /> Petty Cash
                </Th>
                <Th colSpan={2} style={{ minWidth: 180 }} className="p-1">
                  Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 80 }} className="p-1">
                  Sisa Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Status Approval
                </Th>
                <ThFixed rowSpan={2}>Aksi</ThFixed>
              </Tr>
              <Tr>
                <Th style={{ width: 85 }} className="p-1">
                  Tgl. Awal Petty Cash
                </Th>
                <Th style={{ width: 85 }} className="p-1">
                  Tgl. Akhir Petty Cash
                </Th>
              </Tr>
            </THead>
            <TBody>
              {getPenerimaPettyCash?.data?.data?.length > 0 ? (
                getPenerimaPettyCash?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_penerimaan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_penerimaan_petty_cash}</div>
                    </Td>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_mutasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_bukti_mutasi}</div>
                    </Td>
                    <Td>{e.nama_proyek}</Td>
                    <Td className="text-right">
                      {rupiahConvert().getWithComa(
                        e.nominal_penerimaan_petty_cash
                      )}
                    </Td>
                    <Td className="text-center">
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_awal_petty_cash)
                      )}
                    </Td>
                    <Td className="text-center">
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_akhir_petty_cash)
                      )}
                    </Td>
                    <Td className="text-center">{e?.sisa_durasi ?? 0} hari</Td>
                    <Td className="text-center">
                      {STATUS_APPROVAL[e.status_approval ?? 'PEN']}
                    </Td>
                    <TdFixed className="text-center">
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() =>
                            onDetailButtonClickHandler(
                              e.id_penerimaan_petty_cash
                            )
                          }
                        />
                        {e.status_approval === 'REV' && (
                          <ButtonUpdate
                            icon
                            noText
                            onClick={() =>
                              onUpdateButtonClickHandler(
                                e.id_penerimaan_petty_cash
                              )
                            }
                          />
                        )}
                      </ButtonGroup>
                    </TdFixed>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={getPenerimaPettyCash?.data?.data_count}
            currentPage={pagination?.page}
            totalPage={getPenerimaPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}
    </CRUDLayout>
  );
};
