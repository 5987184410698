import Services from '../services'

class JobAnalisaBarangJadiApi {
    // API SERVER
    get(params) {
        return Services.get('approval_analisa_barang_jadi/list', { params })
    }

    getHistory(params) {
        return Services.get('approval_analisa_barang_jadi/history/list', { params })
    }

    getSingle(params) {
        return Services.get('approval_analisa_barang_jadi/detail', { params })
    }

    approve(data) {
        return Services.post('approval_analisa_barang_jadi/approve', data)
    }
}

export default new JobAnalisaBarangJadiApi()