import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { ApprovalPurchaseOrderApi } from "api";

const DetailApprovalPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_po = state?.no_po;
  const tab = state?.tab ?? "";
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPO, setDataPO] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalPurchaseOrderApi.getSingle({ no_purchase_order: state.no_po })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataPO(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoPurchaseOrder = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: dataPO.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataPO.detail.baseline,
    };

    ApprovalPurchaseOrderApi.save(finalValues)
      .then(() =>
        history.push("/human-resource/approval/purchase-order", {
          alert: {
            show: true,
            variant: "primary",
            text: "Approval berhasil disimpan!",
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    no_po ? getInitialData() : whenNoPurchaseOrder();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <>
        <table style={{ fontSize: "14px" }}>
          <tbody>
            <InfoItem
              title="Tgl. Purchase Order"
              value={
                dataPO?.detail?.tgl_purchase_order
                  ? DateConvert(new Date(dataPO?.detail?.tgl_purchase_order))
                      .detail
                  : "-"
              }
            />
            <InfoItem
              title="No. Purchase Order"
              value={
                dataPO?.detail?.no_purchase_order
                  ? dataPO?.detail?.no_purchase_order
                  : "-"
              }
            />
            <InfoItem
              title="Pembayaran"
              value={
                dataPO?.detail?.pembayaran ? dataPO.detail.pembayaran : "-"
              }
            />
            <InfoItem
              title="Tanggal Pengiriman"
              value={
                dataPO?.detail?.tgl_pengiriman
                  ? DateConvert(new Date(dataPO?.detail?.tgl_pengiriman)).detail
                  : "-"
              }
            />
            <InfoItem
              title="Alamat Pengiriman"
              value={
                dataPO?.detail?.alamat_tujuan_pengiriman
                  ? dataPO?.detail?.alamat_tujuan_pengiriman
                  : "-"
              }
            />
            <InfoItem
              title="Keterangan"
              value={
                dataPO?.detail?.keterangan ? dataPO.detail.keterangan : "-"
              }
            />
          </tbody>
        </table>

        <hr />
        <table style={{ fontSize: "14px" }}>
          <InfoItem
            title="Kode Vendor"
            value={
              dataPO?.detail?.kode_vendor ? dataPO?.detail?.kode_vendor : "-"
            }
          />
          <InfoItem
            title="Nama Vendor"
            value={
              dataPO?.detail?.nama_vendor ? dataPO?.detail?.nama_vendor : "-"
            }
          />
          <InfoItem
            title="Contact Person"
            value={
              dataPO?.detail?.contact_person
                ? dataPO?.detail?.contact_person
                : "-"
            }
          />
          <InfoItem
            title="Alamat Vendor"
            value={
              dataPO?.detail?.alamat_vendor
                ? dataPO?.detail?.alamat_vendor
                : "-"
            }
          />
        </table>
      </>
    );
  };

  const TableSection = () => {
    const dataDiskon = dataPO?.detail?.diskon ? dataPO.detail.diskon : 0;
    const dataPPN = dataPO?.detail?.ppn ? dataPO.detail.ppn : 0;
    const dataDP = dataPO?.detail?.dp ? dataPO.detail.dp : 0;
    const dataTable = dataPO?.detail?.po_detail ? dataPO.detail.po_detail : [];

    const getTotal = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty_order ? parseFloat(current.qty_order) : 0;
        const price = current.harga_kesepakatan
          ? parseFloat(current.harga_kesepakatan)
          : 0;

        const total = parseFloat(price * qty);
        return parseInt(parseFloat(prev) + parseFloat(total));
      }, 0);

      return subTotal;
    };

    const getTotalBiayaAngkut = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty_order ? parseFloat(current.qty_order) : 0;
        const price = current.harga_kesepakatan_biaya_angkut
          ? parseFloat(current.harga_kesepakatan_biaya_angkut)
          : 0;

        const total = parseFloat(price * qty);
        return parseInt(parseFloat(prev) + parseFloat(total));
      }, 0);

      return subTotal;
    };

    const getDiscount = () => {
      const total = getTotal();
      const discount = parseFloat(dataDiskon);
      const totalDiscount = (total / 100) * discount;

      return parseInt(totalDiscount);
    };

    const getTotalAfterDiscount = () => {
      const total = getTotal();
      const totalDiscount = getDiscount();
      const totalAfterDiscount = total - totalDiscount;

      return parseInt(totalAfterDiscount);
    };

    const getPPN = () => {
      const totalAfterDiscount = getTotalAfterDiscount();
      const ppn = parseFloat(dataPPN);
      const totalDiscount = (totalAfterDiscount / 100) * ppn;

      return parseInt(totalDiscount);
    };

    const getTotalAfterPPN = () => {
      const totalAfterDiscount = getTotalAfterDiscount();
      const totalPPN = getPPN();
      const totalAfterPPN = totalAfterDiscount + totalPPN;

      return parseInt(totalAfterPPN);
    };

    const getGrandTotal = () => getTotalAfterPPN() + getTotalBiayaAngkut();

    const getDP = () => {
      const totalAfterPPN = getGrandTotal();
      const dp = parseFloat(dataDP);
      const totalDP = (totalAfterPPN / 100) * dp;

      return parseInt(totalDP);
    };

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;

      const total = parseFloat(dataQty * dataHarga);

      return RupiahConvert(total.toString()).getWithComa;
    };

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          fontSize: "14px",
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={props.className}
        style={{
          ...props.style,
          fontSize: "14px",
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th>No.</Th>
              <Th>Kode Item</Th>
              <Th>Nama Item</Th>
              <Th>Proyek</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty Order</Th>
              <Th>Harga Kesepakatan</Th>
              <Th>Vendor Angkut</Th>
              <Th>Harga Kesepakatan Biaya Angkut</Th>
              <Th>Jumlah</Th>
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  {/* <Td>
                    {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                            ? val.nama_item
                            : ""}
                  </Td> */}
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_proyek ?? "-"}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td className="text-right">
                    {decimalConvert(val.qty_order ?? 0)}
                  </Td>
                  <Td className="text-right">
                    {val.harga_kesepakatan
                      ? RupiahConvert(
                          parseInt(val.harga_kesepakatan).toString()
                        ).getWithComa
                      : "-"}
                  </Td>
                  <Td>{val.nama_vendor_angkut ?? "-"}</Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(
                        parseFloat(
                          val.harga_kesepakatan_biaya_angkut ?? 0
                        ).toString()
                      ).getWithComa
                    }
                  </Td>
                  <Td className="text-right">
                    {getSubTotal(val.qty_order, val.harga_kesepakatan)}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <Th colSpan={9} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotal().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="text-right">
                Diskon ({decimalConvert(dataDiskon)}%)
              </Th>
              <Th className="text-right">
                {RupiahConvert(getDiscount().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="bg-light text-right">
                Total Setelah Diskon
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotalAfterDiscount().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="text-right">
                PPN ({decimalConvert(dataPPN)}%)
              </Th>
              <Th className="text-right">
                {RupiahConvert(getPPN().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="bg-light text-right">
                Total Setelah PPN
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotalAfterPPN().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="bg-light text-right">
                Total Biaya Angkut
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotalBiayaAngkut().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="bg-light text-right">
                Grand Total
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getGrandTotal().toString()).getWithComa}
              </Th>
            </tr>
            <tr>
              <Th colSpan={9} className="text-right">
                DP ({decimalConvert(dataDP)}%)
              </Th>
              <Th className="text-right">
                {RupiahConvert(getDP().toString()).getWithComa}
              </Th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty,
  }) => {
    const dataPengaju = dataPO?.approval ? dataPO.approval : [];
    const dataDetail = dataPO.detail ? dataPO.detail : "-";

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Purchase Order</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER"
                      ? `Pemeriksa ${val.approval_level}`
                      : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {dataDetail.status_approval === "VER" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Purchase Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Purchase Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Purchase Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "revise",
                            title: (
                              <span className="text-warning">
                                Revise Purchase Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Purchase Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Purchase Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSection />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan"),
          })}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            dirty,
          }) => (
            <>
              <FormCard
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                validateForm={validateForm}
                dirty={dirty}
              />
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalPurchaseOrder;
