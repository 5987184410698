import React, { Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import {
  Input,
  InfoItemVertical,
  Select,
  TextArea,
  DatePicker,
  InputCurrency,
} from "components2";
import { dateConvert, rupiahConvert } from "utilities2";
import { getAkumulasiProyek, KODE_MODUL } from "../__PengajuanPettyCashUtils__";
import PengajuanPettyCashApi from "../__PengajuanPettyCashApi__";

export const FormPengajuanPettyCashContent = ({ action }) => {
  const inputCurrencyConf = {
    className: "d-flex flex-column text-right",
    decimalSeparator: ",",
    thousandSeparator: ".",
    decimalScale: 2,
  };

  const { values, errors, touched, setValues, handleChange } =
    useFormikContext();

  // fetch dropdown proyek
  const { data: proyek, isLoading: isLoadingProyek } = useQuery(
    ["proyek", "dropdown"],
    () => PengajuanPettyCashApi.getDropdownProyek()
  );

  // fetch limit dan maksimal pengajuan
  const { isLoading: isLoadingAkumulasi, isRefetching: isRefetchingAkumulasi } =
    useQuery({
      queryKey: ["akumulasi", "proyek", values.id_penerima_petty_cash],
      queryFn: () =>
        getAkumulasiProyek({
          id_penerima_petty_cash: values.id_penerima_petty_cash,
        }),
      enabled: Boolean(values.id_penerima_petty_cash),
      onSuccess: (data) => {
        const maskimalPengajuan = calcMaksPengajuanPettyCash({
          limit_petty_cash: values.limit_petty_cash,
          akumulasi_diajukan: data.akumulasi_diajukan,
          akumulasi_penerimaan: data.akumulasi_penerimaan,
        });

        setValues({
          ...values,
          akumulasi_diajukan: data.akumulasi_diajukan,
          akumulasi_penerimaan: data.akumulasi_penerimaan,
          maksimal_pengajuan: maskimalPengajuan,
        });
      },
      onError: ({ response }) =>
        window.alert(response?.data?.message ?? "Gagal memuat akumulasi"),
    });

  const { isLoading: isLoadingGenerateNumber } = useQuery(
    ["generateNoPettyCash", values?.tgl_pengajuan_petty_cash],
    () =>
      PengajuanPettyCashApi.generateNumber({
        tanggal: values?.tgl_pengajuan_petty_cash,
      })
        .then((res) =>
          setValues((prev) => ({
            ...prev,
            no_pengajuan_petty_cash: res.data.data ?? "",
          }))
        )
        .catch(() =>
          window.alert("Tidak dapat memuat Nomor Pengajuan Petty Cash")
        ),
    {
      enabled: Boolean(action === "CREATE" && values?.tgl_pengajuan_petty_cash),
    }
  );

  const calcMaksPengajuanPettyCash = ({
    limit_petty_cash,
    akumulasi_diajukan,
    akumulasi_penerimaan,
  }) =>
    parseInt(limit_petty_cash ?? 0) -
    (parseInt(akumulasi_diajukan ?? 0) + parseInt(akumulasi_penerimaan ?? 0));

  useEffect(() => {
    if (action === "UPDATE" && values.id_pengajuan_petty_cash) {
      getAkumulasiProyek({
        id_pengajuan_petty_cash: values.id_pengajuan_petty_cash,
      }).then((res) => {
        const maskimalPengajuan = calcMaksPengajuanPettyCash({
          limit_petty_cash: values.limit_petty_cash,
          akumulasi_diajukan: res.akumulasi_diajukan,
          akumulasi_penerimaan: res.akumulasi_penerimaan,
        });

        setValues({
          ...values,
          akumulasi_diajukan: res.akumulasi_diajukan,
          akumulasi_penerimaan: res.akumulasi_penerimaan,
          maksimal_pengajuan: maskimalPengajuan,
        });
      });
    }
  }, [action, values.id_pengajuan_petty_cash]);

  if (action === "DETAIL") {
    return (
      <Fragment>
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Pengajuan Petty Cash"
              text={dateConvert().getSlashDMY(
                new Date(values?.tgl_pengajuan_petty_cash)
              )}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Pengajuan Petty Cash"
              text={values?.no_pengajuan_petty_cash}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Proyek" text={values?.nama_proyek} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Divisi" text={values?.kode_modul} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Limit Saldo Petty Cash"
              text={rupiahConvert().getWithComa(
                String(values?.limit_petty_cash)
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Akumulasi Petty Cash Yang Diajukan"
              text={rupiahConvert().getWithComa(
                String(values?.akumulasi_diajukan)
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Akumulasi Penerimaan Petty Cash"
              text={rupiahConvert().getWithComa(
                String(values?.akumulasi_penerimaan)
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Maksimal Pengajuan Petty Cash"
              text={rupiahConvert().getWithComa(
                String(values?.maksimal_pengajuan)
              )}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Nominal Pengajuan Petty Cash"
              text={rupiahConvert().getWithComa(
                String(values?.nominal_pengajuan)
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InfoItemVertical label="Keperluan" text={values?.keperluan} />
          </Col>
        </Row>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <DatePicker
            label="Tgl. Pengajuan Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_pengajuan_petty_cash
                ? new Date(values.tgl_pengajuan_petty_cash)
                : ""
            }
            onChange={async (date) =>
              setValues({ ...values, tgl_pengajuan_petty_cash: date })
            }
            error={
              errors.tgl_pengajuan_petty_cash &&
              touched.tgl_pengajuan_petty_cash &&
              true
            }
            errorText={errors.tgl_pengajuan_petty_cash}
          />
        </Col>

        <Col>
          <Input
            disabled
            label="No. Pengajuan Petty Cash"
            className="d-flex flex-column"
            loading={isLoadingGenerateNumber}
            value={values.no_pengajuan_petty_cash}
          />
        </Col>
      </Row>

      <Select
        label="Proyek"
        placeholder="Pilih Proyek"
        name="id_penerima_petty_cash"
        options={proyek ?? []}
        loading={isLoadingProyek}
        onChange={async (val) =>
          setValues({
            ...values,
            id_penerima_petty_cash: val.value,
            nama_proyek: val.label,
            limit_petty_cash: val.limit_petty_cash,
          })
        }
        defaultValue={
          proyek?.find(
            ({ value }) => value === values.id_penerima_petty_cash
          ) ?? null
        }
        error={
          errors.id_penerima_petty_cash &&
          touched.id_penerima_petty_cash &&
          true
        }
        errorText={
          touched.id_penerima_petty_cash && errors.id_penerima_petty_cash
        }
      />

      <Select
        label="Divisi"
        placeholder="Pilih Divisi"
        name="kode_modul"
        options={KODE_MODUL ?? []}
        onChange={async ({ value }) =>
          setValues({
            ...values,
            kode_modul: value,
          })
        }
        defaultValue={
          KODE_MODUL?.find(({ value }) => value === values.kode_modul) ?? null
        }
        error={errors.kode_modul && touched.kode_modul && true}
        errorText={touched.kode_modul && errors.kode_modul}
      />

      <Row>
        <Col>
          <InputCurrency
            id={values.id_penerima_petty_cash}
            {...inputCurrencyConf}
            disabled
            label="Limit Saldo Petty Cash"
            loading={isLoadingAkumulasi || isRefetchingAkumulasi}
            value={parseFloat(values.limit_petty_cash ?? 0)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InputCurrency
            {...inputCurrencyConf}
            disabled
            label="Akumulasi Petty Cash Yang Sedang Diajukan"
            loading={isLoadingAkumulasi || isRefetchingAkumulasi}
            value={parseFloat(values.akumulasi_diajukan ?? 0)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InputCurrency
            {...inputCurrencyConf}
            disabled
            label="Akumulasi Penerimaan Petty Cash"
            loading={isLoadingAkumulasi || isRefetchingAkumulasi}
            value={parseFloat(values.akumulasi_penerimaan ?? 0)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InputCurrency
            {...inputCurrencyConf}
            disabled
            label="Maksimal Pengajuan Petty Cash"
            loading={isLoadingAkumulasi || isRefetchingAkumulasi}
            value={parseFloat(values.maksimal_pengajuan ?? 0)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InputCurrency
            {...inputCurrencyConf}
            name="nominal_pengajuan"
            label="Nominal Pengajuan Petty Cash"
            onChange={(e) => setValues({ ...values, nominal_pengajuan: e })}
            value={values.nominal_pengajuan}
            error={Boolean(
              errors.nominal_pengajuan && touched.nominal_pengajuan
            )}
            errorText={
              Boolean(errors.nominal_pengajuan) && errors.nominal_pengajuan
            }
          />
        </Col>
      </Row>

      <TextArea
        label="Keperluan"
        placeholder="Masukkan Keperluan"
        name="keperluan"
        value={values.keperluan}
        onChange={handleChange}
        error={Boolean(errors.keperluan && touched.keperluan)}
        errorText={Boolean(errors.keperluan) && errors.keperluan}
      />
    </Fragment>
  );
};
