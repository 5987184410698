// Pre-form Page

import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
} from "components";
import { InputSearch, DataStatus, ButtonCreate, ButtonBack } from "components2";
import { tableNumber, dateConvert, rupiahConvert } from "utilities2";
import PenerimaanPettyCashApi from "./__PenerimaanPettyCashApi__";

export const PenerimaanMutasiPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const { data, isLoading, isError } = useQuery(
    ["penerimaan_petty_cash", "mutasi", "list", pagination],
    () => PenerimaanPettyCashApi.getListMutasi(pagination)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = (no_bukti_mutasi) =>
    history.push("/human-resource/transaksi/penerimaan-petty-cash/tambah", {
      no_bukti_mutasi,
    });

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  useEffect(() => setNavbarTitle("Penerimaan Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Mutasi Petty Cash
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed rowSpan={2}>No.</ThFixed>
                <Th rowSpan={2} style={{ width: 100 }} className="p-1">
                  Informasi Bukti Mutasi
                </Th>
                <Th rowSpan={2} style={{ width: 100 }} className="p-1">
                  Informasi Pengajuan <br /> Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 120 }} className="p-1">
                  Proyek
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Nominal Mutasi <br /> Petty Cash
                </Th>
                <Th colSpan={2} style={{ minWidth: 180 }} className="p-1">
                  Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 120 }} className="p-1">
                  Keterangan
                </Th>
                <ThFixed rowSpan={2}>Aksi</ThFixed>
              </Tr>
              <Tr>
                <Th style={{ width: 85 }} className="p-1">
                  Tgl. Awal Petty Cash
                </Th>
                <Th style={{ width: 85 }} className="p-1">
                  Tgl. Akhir Petty Cash
                </Th>
              </Tr>
            </THead>
            <TBody>
              {data?.data?.length > 0 ? (
                data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_mutasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_bukti_mutasi}</div>
                    </Td>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_pengajuan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_pengajuan_petty_cash}</div>
                    </Td>
                    <Td>{e.nama_proyek}</Td>
                    <Td className="text-right">
                      {rupiahConvert().getWithComaWithoutLabel(
                        e.nominal_pengajuan
                      )}
                    </Td>
                    <Td className="text-center">
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_awal_petty_cash)
                      )}
                    </Td>
                    <Td className="text-center">
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_akhir_petty_cash)
                      )}
                    </Td>
                    <Td>{e.keperluan}</Td>
                    <TdFixed className="text-center">
                      <ButtonCreate
                        icon
                        noText
                        onClick={() =>
                          onCreateButtonClickHandler(e.no_bukti_mutasi)
                        }
                      />
                    </TdFixed>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={data?.data_count}
            currentPage={pagination?.page}
            totalPage={data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}
    </CRUDLayout>
  );
};
