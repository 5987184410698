import Services from '../../services'

class PenawaranApi {
    get(params) {
        return Services.get('approval_penawaran/list', { params })
    }

    getHistory(params) {
        return Services.get('approval_penawaran/history/list', { params })
    }

    getSinglePenawaran(params) {
        return Services.get('approval_penawaran/detail', { params })
    }

    getAnalisaPenawaran(params) {
        return Services.get('approval_rab/analisa_barang_jadi_single', { params })
    }

    approve(data) {
        return Services.post('approval_penawaran/approve', data)
    }
}

export default new PenawaranApi()