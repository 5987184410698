import Services from "../../services";

class PengakuanHutangApi {
  // API SERVER
  get(params) {
    return Services.get("approval_penyesuaian_hutang/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_penyesuaian_hutang/history/list", {
      params,
    });
  }

  getSingle(params) {
    return Services.get("approval_penyesuaian_hutang/detail", { params });
  }

  approve(data) {
    return Services.post("approval_penyesuaian_hutang/approve", data);
  }
}

export default new PengakuanHutangApi();
