import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { PenerimaPettyCashApi } from 'api';

const ApprovalPenerimaPettyCash = () => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PenerimaPettyCashApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Pembuat Pengajuan</ThFixed>
            <ThFixed>Informasi Penerima Petty Cash</ThFixed>
            <ThFixed>No. Pekerja</ThFixed>
            <Th>Pekerja</Th>
            <Th>Proyek</Th>
            <Th>Keterangan</Th>
            <Th style={{ width: '8.5rem' }}>Aksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <TdFixed>
                <div>{val.nama_karyawan_pengaju ?? '-'}</div>
                <div>{val.nama_jabatan_pengaju ?? '-'}</div>
              </TdFixed>
              <TdFixed>
                <div>
                  {val.tgl_penerima_petty_cash
                    ? DateConvert(new Date(val.tgl_penerima_petty_cash))
                        .defaultDMY
                    : '-'}
                </div>
                <div>{val.no_penerima_petty_cash ?? '-'}</div>
              </TdFixed>
              <Td>{val.no_karyawan ?? '-'}</Td>
              <Td>{val.nama_karyawan ?? '-'}</Td>
              <Td>{val.nama_proyek ?? '-'}</Td>
              <Td>{val.keterangan ?? '-'}</Td>
              <Td>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    text="Lihat Detail"
                    className="col"
                    onClick={() =>
                      history.push(
                        `/human-resource/approval/penerima-petty-cash/detail/${val.id_penerima_petty_cash}`,
                        {
                          tab: 'approval',
                          no_penerima_petty_cash: val.no_penerima_petty_cash,
                        }
                      )
                    }
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                placeholder="Cari RAE"
                value={searchConfig.key}
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: 'primary',
                    text: 'Hasil dari pencarian: ' + key,
                  });
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data ? (
        data.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default ApprovalPenerimaPettyCash;
