import Services from 'services';

const Mock = {
  get: (url, params) =>
    new Promise((res, rej) =>
      setTimeout(() => {
        switch (url) {
          case '/pengajuan_petty_cash/page':
            res({
              data: {
                data: [
                  {
                    tgl_pengajuan_petty_cash: '2022-11-01',
                    no_pengajuan_petty_cash: '2022/02/PC/000001',
                    nama_proyek: 'testing',
                    id_proyek: '1',
                    keperluan: 'coba keperluan pengajuan',
                  },
                ],
                total_page: 1,
              },
            });
            break;

          case '/v1/example/single':
            res({
              data: {
                tanggal: '2020-01-01',
                keterangan: '',
                karyawan: Array.from({ length: 2 }).map((_, i) => ({
                  id: i + 1,
                  nama: `User ${i + 1}`,
                })),
                detail: Array.from({ length: 50 }).map((_, i) => ({
                  barang: `Barang ${i}`,
                  qty: i + 10,
                  harga: i + 1000,
                })),
              },
            });
            break;

          case '/pengajuan_petty_cash/d_proyek':
            res({
              data: Array.from({ length: 50 }).map((_, i) => ({
                id: i,
                nama: `User ${i}`,
              })),
            });
            break;

          case '/pengajuan_petty_cash/no_baru':
            res({
              data: { data: '2022/02/PC/000001' },
            });
            break;

          default:
            res({});
        }
      }, 500)
    ),
  post: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
  patch: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
  put: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
  delete: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
};

class PengajuanPettyCashApi {
  async getList(params) {
    const fetch = await Services.get('pengajuan_petty_cash/page', {
      params,
    });
    return fetch.data;
  }

  getSingle(params) {
    return Services('/pengajuan_petty_cash/single/', { params });
  }

  async getDropdownProyek() {
    const fetch = await Services.get('pengajuan_petty_cash/d_proyek');
    return fetch?.data?.data?.map((val) => ({
      label: `${val.nama_proyek} - ${val.nama_karyawan}`,
      value: val.id_penerima_petty_cash,
      limit_petty_cash: val.limit_petty_cash,
    }));
  }

  getAkumulasi(params) {
    return Services.get('pengajuan_petty_cash/akumulasi', { params });
  }

  generateNumber(params) {
    return Services.get('pengajuan_petty_cash/no_baru', { params });
  }

  create(data) {
    return Services.post('pengajuan_petty_cash', data);
  }

  update(data) {
    return Services.put('pengajuan_petty_cash', data);
  }
}

export default new PengajuanPettyCashApi();
