import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  TextArea,
  Approval,
} from 'components';
import { ApprovalStatus, DateConvert } from 'utilities';
import { PenerimaPettyCashApi } from 'api';

const DetailApprovalPenerimaPettyCash = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();

  const tab = state?.tab;
  const no_penerima_petty_cash = state?.no_penerima_petty_cash;
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [approveStatus, setApproveStatus] = useState('V');
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PenerimaPettyCashApi.getSingle({ no_penerima_petty_cash })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : 'V';
        setDataDetail(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: dataDetail.detail.no_penerima_petty_cash,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
    };

    PenerimaPettyCashApi.approve(finalValues)
      .then(() =>
        history.push('/human-resource/approval/penerima-petty-cash', {
          alert: {
            show: true,
            variant: 'primary',
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Approval gagal disimpan!',
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle('Approval Penerima Petty Cash');
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop = false }) => (
      <tr>
        <td
          className="align-top"
          style={isPaddingTop ? { paddingTop: '10px' } : {}}
        >
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    );

    return (
      <>
        <Row>
          <Col md>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. Penerima Petty Cash"
                  value={
                    dataDetail?.detail?.tgl_penerima_petty_cash
                      ? DateConvert(
                          new Date(dataDetail?.detail?.tgl_penerima_petty_cash)
                        ).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="No. Penerima Petty Cash"
                  value={dataDetail?.detail?.no_penerima_petty_cash ?? '-'}
                />
                <InfoItem
                  title="No. Pekerja"
                  value={dataDetail?.detail?.no_karyawan ?? ''}
                />
                <InfoItem
                  title="Pekerja"
                  value={dataDetail?.detail?.nama_karyawan ?? '-'}
                />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Unit Organisasi"
                  value={dataDetail?.detail?.nama_unit_organisasi ?? '-'}
                />
                <InfoItem
                  title="Proyek"
                  value={dataDetail?.detail?.nama_proyek ?? '-'}
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />

        <TextArea
          label="Keterangan"
          value={dataDetail?.detail?.keterangan ?? '-'}
          readOnly
          rows="3"
        />
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Penerima Petty Cash</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <InfoSection />
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: '' }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required('Masukan catatan'),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={dataDetail?.approval ? dataDetail.approval : []}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Penerimaan Petty Cash"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalPenerimaPettyCash;
