// Import depedencies
import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout, PageLoader } from "../components";

// Import page dashboard
import Dashboard from "../pages/Dashboard";

// Import page 404
import NotFound from "../pages/404";

import { AuthContext, NotifContext } from "../utilities";

import Login from "../pages/Login";
import Config from "../config";
import { AuthApi } from "api";

const MyRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataNotif, setDataNotif] = useState({});

  const { state } = useContext(AuthContext);
  const { isAuthenticated, role } = state;
  const { ROUTES } = Config;

  // Fetch notifikasi
  const checkNotifHandler = () => {
    setIsLoading(true);

    AuthApi.notif()
      .then((res) => {
        const notif = res.data.data;
        setDataNotif(notif);
      })
      .catch(() => {
        window.alert("Notifikasi gagal diperoleh!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    isAuthenticated && checkNotifHandler();
    !isAuthenticated && setIsLoading(false);
  }, [isAuthenticated, role]);

  if (role.length > 0) {
    role.map((val) => {
      let split = val?.split("_");
      if (split.length == 5) {
        let hak = `${split[0]}_${split[1]}_${split[2]}`;
        role.push(hak);
      }
    });
  }

  // useEffect(() => { }, [state]);

  const AuthenticatedRoutes = ({ setNavbarTitle }) => (
    <Switch>
      {/* DASHBOARD */}
      <Route exact path="/">
        <Dashboard setNavbarTitle={setNavbarTitle} />
      </Route>

      {/* ROUTES */}
      {ROUTES.map(
        (val, index) =>
          val.hak &&
          val.hak.some((hak) => role.includes(hak)) && (
            <Route key={index} exact={val.exact} path={val.route}>
              <val.page setNavbarTitle={setNavbarTitle} />
            </Route>
          )
      )}

      {/* LOGIN FORM */}
      <Route path="/login">
        <Redirect to="/" />
      </Route>

      {/* 404-NOT FOUND */}
      <Route path="*">
        <NotFound setNavbarTitle={setNavbarTitle} />
      </Route>
    </Switch>
  );

  const UnAuthRoutes = () => (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <NotifContext.Provider value={dataNotif}>
      <BrowserRouter basename="/">
        {!isAuthenticated ? (
          <UnAuthRoutes />
        ) : (
          <Layout>
            <AuthenticatedRoutes />
          </Layout>
        )}
      </BrowserRouter>
    </NotifContext.Provider>
  );
};

export default MyRoutes;
