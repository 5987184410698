import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Input, DatePicker, InputCurrency } from 'components2';
import { rupiahConvert } from 'utilities2';
import { generateNoPettyCash } from '../__PengembalianSisaPettyCashUtils__';

export const RealisasiPettyCashContent = ({ action }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const TODAY = new Date();

  useEffect(
    () =>
      (async () => {
        if (action === 'CREATE') {
          const noPettyCash = await generateNoPettyCash(TODAY);

          setValues({
            ...values,
            no_pengembalian_sisa_petty_cash: noPettyCash,
            tgl_pengembalian_sisa_petty_cash: TODAY,
          });
        }
      })(),
    [action]
  );

  return (
    <Fragment>
      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Penerimaan Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_penerimaan_petty_cash
                ? new Date(values.tgl_penerimaan_petty_cash)
                : ''
            }
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="No. Penerimaan Petty Cash"
            className="d-flex flex-column"
            value={values.no_penerimaan_petty_cash}
          />
        </Col>
        <Col md="3">
          <InputCurrency
            disabled
            label="Nominal Penerimaan Petty Cash"
            className="d-flex flex-column text-right"
            value={rupiahConvert().getWithComa(
              values.nominal_penerimaan_petty_cash
            )}
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="Proyek"
            className="d-flex flex-column"
            value={values.nama_proyek}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <span className="font-weight-bold" style={{ fontSize: '.85rem' }}>
            Masa Berlaku Petty Cash
          </span>
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Awal Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_awal_petty_cash
                ? new Date(values.tgl_awal_petty_cash)
                : ''
            }
          />
        </Col>

        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Akhir Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_akhir_petty_cash
                ? new Date(values.tgl_akhir_petty_cash)
                : ''
            }
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="Sisa Masa Berlaku Petty Cash"
            className="d-flex flex-column"
            value={`${values.sisa_durasi} hari`}
          />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Realisasi Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_realisasi_petty_cash
                ? new Date(values.tgl_realisasi_petty_cash)
                : ''
            }
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="No. Realisasi Petty Cash"
            className="d-flex flex-column"
            value={values.no_realisasi_petty_cash}
          />
        </Col>

        <Col md="3">
          <InputCurrency
            disabled
            label="Sisa Petty Cash"
            className="d-flex flex-column text-right"
            value={rupiahConvert().getWithComa(values.sisa_petty_cash)}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <DatePicker
            disabled={action === 'DETAIL'}
            label="Tgl. Pengembalian Sisa Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_pengembalian_sisa_petty_cash
                ? new Date(values.tgl_pengembalian_sisa_petty_cash)
                : ''
            }
            onChange={async (date) => {
              if (action === 'CREATE') {
                const noPettyCash = await generateNoPettyCash(date);

                setValues({
                  ...values,
                  no_pengembalian_sisa_petty_cash: noPettyCash,
                  tgl_pengembalian_sisa_petty_cash: date,
                });
                return;
              }
              setValues({ ...values, tgl_pengembalian_sisa_petty_cash: date });
            }}
            error={
              errors.tgl_pengembalian_sisa_petty_cash &&
              touched.tgl_pengembalian_sisa_petty_cash
            }
            errorText={errors.tgl_pengembalian_sisa_petty_cash}
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="No. Pengembalian Sisa Petty Cash"
            className="d-flex flex-column"
            value={values.no_pengembalian_sisa_petty_cash}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
