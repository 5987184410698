import Services from '../../services'

// API TERBARU PURCHASE REQUEST

class PurchaseRequestApi {
    get(params) {
        return Services.get('approval_purchase_request/list', { params })
    }

    getHistory(params) {
        return Services.get('approval_purchase_request/history/list', { params })
    }

    getSingle(params) {
        return Services.get('approval_purchase_request/detail', { params })
    }

    approve(data) {
        return Services.post('approval_purchase_request/approve', data)
    }

    getDropdownItem() {
        return Services.get('approval_purchase_request/item_barang')
    }

    getDropdownJabatan() {
        return Services.get('approval_purchase_request/jabatan')
    }

    getDropdownKaryawan(id) {
        return Services.get(`/approval_purchase_request/karyawan?id_jabatan=${id}`)
    }
}

export default new PurchaseRequestApi()